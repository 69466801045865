import type { NextPage } from "next";

import PageWithoutNav from "layouts/PageWithoutNav";
import LoginForm from "component/forms/LoginForm";
import useParamFromPath from "hooks/useParamFromPath";

import { Container, ContentCard, GridContainer, GridItem, H2 } from "element";

const Login: NextPage = () => {
  const redirectUrl = useParamFromPath("redirect_url");
  return (
    <PageWithoutNav>
      <Container maxWidth="sm">
        <ContentCard>
          <GridContainer>
            <GridItem>
              <H2>Log In</H2>
            </GridItem>
            <GridItem>
              <LoginForm redirectUrl={redirectUrl} />
            </GridItem>
          </GridContainer>
        </ContentCard>
      </Container>
    </PageWithoutNav>
  );
};

export default Login;
